import wamhlogo from '../media/we-all-make-history/itsmarcosjreyes-header-small-wamh-top.svg';
import wamhcodelogo from '../media/we-all-make-history/itsmarcosjreyes-header-small-wamh-logo.svg';
import wamh01 from '../media/we-all-make-history/we-all-make-history-lets-wamh-it-up-01-min.png';
import React, { useEffect } from "react";
import './App.css';

const MARCOSJREYES_RESUME = './resume/MarcosJReyes-Apple-Sr-Lead-MobileEngineer-Mar2022.pdf';
const WAMH_DISCORD = 'https://discord.gg/3U4xmcmhr2';

const goToWAMHDiscord = async () => {
  window.open(WAMH_DISCORD);
};

function WAMH() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <div className="main-container">
      <header className="header-branding-container">
        <img src={wamhlogo} className="header-branding-logo" alt="itsmarcosjreyes" />
        <img src={wamhcodelogo} className="header-branding-logo-code" alt="itsmarcosjreyes" />
        <p className="header-title-text">We All Make History</p>
        <p className="header-branding-subtext">Changing the world positively and leaving a record on the blokchain forever</p>

        <div className="divider-orange"></div>
      </header>
        
        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Let's WAMH it up! 👋</h2>
          <div className="">
            <p className="cool-project-description">We All Make History is an NFT project where the proceeds will go towards solving 16 major world wide problems.<br/><br/>
            Every year, I will pick one of the 16 problems to really zone in and help organizations, create new organizations if needed and whatever actionable items need to be executed to actually help correct the problem.<br/><br/>
            As the project gains more traction and people buy and sell their NFTs to help the world, the royalties will be re-invested towards helping solve these problems.<br/><br/>
            Every year, a digital time capsule will be written on the blockchain with the names (if they desire) of all those who helped by being involved with the NFT during that year to make the world a better place.<br/><br/>
            Those time capsules will forever live throughout human history in blockchain.
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">The problems we want to help solve</h2>
          <div className="">
            <p className="cool-project-description">I understand that these are major problems with major obstacles. That’s ok.<br/><br/>
            I’m a firm believer that the ripples of one’s actions can create such amazing tsunamis of greatness that I want to take ownership and initiative to provide solutions to these problems with a very different approach.<br/><br/>
            <ol className="large-items">
              <li>World Hunger</li>
              <li>Climate Change</li>
              <li>Wild Life Conservation</li>
              <li>Pollution</li>
              <li>Child Trafficking</li>
              <li>Domestic Abuse</li>
              <li>Drinkable Water Distribution</li>
              <li>Open Dialogue</li>
              <li>Lack of Education</li>
              <li>Government Transparency</li>
              <li>Poverty</li>
              <li>Illegal Drugs</li>
              <li>Natural Disasters</li>
              <li>Physical Fitness</li>
              <li>Discrimination</li>
              <li>Transition to Solar Energy</li>
            </ol>
            </p>
          </div>
        </div>
        <button
            className="cta-button cta-purple-animation"
            onClick={goToWAMHDiscord}
          >
            Join Discord and be Part of the Change!
          </button>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">What's next?</h2>
          <div className="">
            <p className="cool-project-description">
            <img src={wamh01} className="section-image" alt="We All Make History" />
              We are currently working on developing the actual NFT collection pouring our hearts in every single layer.<br/>
            We are actively growing our Discord community, because without you, this project can’t make the positive impact it should.<br/>
            We welcome all ideas, all points of view.<br/>
            <b>The bottom line is we need to find solutions.</b><br/><br/>
            Join our Discord and be the first to own an NFT from the one and only WAMH collection!<br/><br/>
            </p>
          </div>
          <h2 className="section-title linear-wipe">Don't just wish for change, be part of it.</h2>
          <button
            className="cta-button cta-purple-animation"
            onClick={goToWAMHDiscord}
          >
            Join Discord and WAMH it up!
          </button>
        </div>

        <div className="divider-orange"></div>

        <div className="footer">
          {/* <a
            className="footer-text"
            href={MARCOSJREYES_RESUME}
            target="_blank"
            rel="noreferrer"
          >Click here to view my resume</a> */}
          <p>© {new Date().getFullYear()} Marcos J. Reyes. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default WAMH;
