import mjrlogo from '../media/itsmarcosjreyes-header-small.svg';
import mjrcodelogo from '../media/consulting/itsmarcosjreyes-header-small-consulting-logo.svg';
import analyzeproblem from '../media/consulting/problem-solving-analyze-min.png';
import appleengineer from '../media/consulting/apple-campus-apple-engineer-expertise-senior-softwre-developer-lead-architect-min.png';
import projectautomation from '../media/consulting/automation-circleci-xcode-continuous-integration-delivery-min.png';
import candidatesinterview from '../media/consulting/interview-candidates-for-my-company-ios-tvos-engineer-min.png';
import startupidea from '../media/consulting/startup-idea-veedah-execute-materialize-engineer-min.png';
import React, { useEffect } from "react";
import './App.css';

const MARCOSJREYES_RESUME = './resume/MarcosJReyes-Apple-Sr-Lead-MobileEngineer-Mar2022.pdf';
const MARCOS_BOOK_ME = 'https://marcosjreyes.simplybook.me/v2/#book';
const bookMarcosJReyes = async () => {
  window.open(MARCOS_BOOK_ME);
};

function Consulting() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <div className="main-container">
      <header className="header-branding-container">
        <img src={mjrlogo} className="header-branding-logo" alt="itsmarcosjreyes" />
        <img src={mjrcodelogo} className="header-branding-logo-code" alt="itsmarcosjreyes" />
        <p className="header-title-text">Consulting</p>

        <div className="divider-orange"></div>
        </header>
        
        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Problem Solving 💡</h2>
          <div className="">
            <p className="cool-project-description">Throughout my long career as a Software Engineer, I have learned that most of the times, a creative solution to a particular problem is more important than anything else. In theory, an algorithm, solves a problem with a few lines of code. In real life, you have users who use multiple apps at the same time, swathing between them. Users who take a phone call in the middle of using your app and then have to open up their baking apps to then come back to a social media app and finish off what they were doing. Users who really want to watch something and their device is about to turn off.<br/><br/> 
            All these and plenty of more scenarios happen every single day and creative solutions are needed for such circumstances to happen and your app to be ready to handle every single one gracefully and intuitively.<br/><br/>
            <b>That’s where I come in for you.</b><br/><br/>
            <img src={analyzeproblem} className="section-image" alt="Problem Solving" />
            I have a gift to be able to look at a problem and break it down so that you can see what the “real” problem is. Besides that, I can provide you with short, mid, and long term solutions to that same problem based on the urgency you have to put a fix out into the world.<br/><br/>
            If you have a bug, a new feature, new idea, or even some rough drafts of the scope of new functionality, I can help you cover all bases and leave out in a sweet spot where you will have full confidence in the development and shipping of the changes.<br/><br/>
            There isn’t a single problem that I can’t help you with. I am a firm believer that there is <b>always</b> a solution; we just have to change our perspective in how we are looking at the problem.
            </p>
          </div>
          <button
            className="cta-button cta-orange-animation"
            onClick={bookMarcosJReyes}
          >
            Pick a time slot
          </button>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Apple Expertise </h2>
          <div className="">
            <p className="cool-project-description">I have been developing apps for the Apple eco-system for over 10 years. I started the same year Apple decided to open the AppStore to developers all over the world.<br/>
            I have experienced the waves of Xamarin, React Native, Flutter, Objective-C to Swift, Evolution of Swift, SwiftUI, tvOS, watchOS.<br/><br/>
            I eagerly stay in sync with everything Apple does. Besides loving their clean products, I truly love the evolution and direction they have in regards to their software.<br/><br/>
            <b>This passionate devotion to following and truly understanding everything Apple does, gives me quite an edge because I’m able to see a bigger picture where many fail to.</b><br/><br/>
            <img src={appleengineer} className="section-image" alt="iOS tvOS watchOS iPadOS Principal Engineer" />
            I’m able to see how these technologies that Apple releases every year can help a business thrive.<br/>
            I’m able to see the real dangers of some of these “magical” one solution for all frameworks that are bone every few amount of years and I can help you identify pivoting plans if there’s a need for them.<br/><br/>
            In my ever-lasting mission to continue growing as an Apple Engineer, I have explored things like integrations with Siri, Universal Search, Catalog Ingestion to integrate with Apple’s TV app, App Clips and many other technologies that are often friction points for many companies because they don’t have enough experience and it becomes a hassle to polish these features. <b>I am thankful that throughout my career, I have been exposed to said features over and over and I can provide a very clear and fast path of execution to implement them.</b>
            </p>
          </div>
          <button
            className="cta-button cta-orange-animation"
            onClick={bookMarcosJReyes}
          >
            Pick a time slot
          </button>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Automation ⚙️</h2>
          <div className="">
            <p className="cool-project-description">If you are part of a team or company that needs automation and haven’t quite found the right solution, you have landed in the right place.<br/>
            I can help you automate your project however you envision it. From PR reviews, to automatically running Unit Tests, to making sure that shared code changes didn’t break one of the platforms you ship to, to submitting specialized builds to your QA teams, to submitting builds to the AppStore for review, all is doable; in a very clean and precise manner.<br/><br/>
            I have experience with CircleCI, Fastlane, Jenkins, AppCenter and Bitrise. It doesn’t matter how complex your project is, or how many schemes or build configurations, we can streamline this process so that it boosts your team’s speed, confidence and deliverability.
            <img src={projectautomation} className="section-image" alt="Automation" />
            </p>
          </div>
          <button
            className="cta-button cta-orange-animation"
            onClick={bookMarcosJReyes}
          >
            Pick a time slot
          </button>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Start-up Idea 💭</h2>
          <div className="">
            <p className="cool-project-description">Each one of us has at least one “AHA!” moment every week. Sometimes an idea is born and we automatically shoot it down ourselves, some other times, we think this idea can revolutionize the world. Some other times, we think the idea is good enough to give it some effort, materialize and “see where it goes”.<br/><br/>
            <b>When you have one of those ideas, I can help you think through what it would actually take to materialize it, short term and long term.</b><br/><br/>
            More often than not, a good conversation is all you need to truly grasp the power of your idea and how fast you can get it off the ground.
            I can guide you through the budget you would be looking at, team size, hiccups you might encounter and everything in between.
            <img src={startupidea} className="section-image" alt="Help with Start-up ideas" />
            </p>
          </div>
          <button
            className="cta-button cta-orange-animation"
            onClick={bookMarcosJReyes}
          >
            Pick a time slot
          </button>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Interview Candidates for you ✔️</h2>
          <div className="">
            <p className="cool-project-description">A huge part of Software Engineering in any company is hiring. The process has always been quite painful. Recruiters try to upsell under-qualified prospects. Team Leads, Directors and Principal Engineers waste quite a lot of time.<br/>
            Being in that position for many years, I truly know how painful that can be. Over the years, I have crafted a very efficient interview process that truly picks out solid candidates and thus I have created amazing teams over the years that have always over-delivered.<br/><br/>
            If you are looking to start a project or if you are a company currently seeking to hire engineers, I can serve you as the first and last point of contact with the candidate, if you choose it to be. 
            <img src={candidatesinterview} className="section-image" alt="I can help you vetting / interviewing candidates" />
            I can help you truly vet candidates and save you the trouble and time investment of interviewing someone who is not qualified for the role you are seeking to fill.<br/><br/>
            <b>When I interview candidates, I don’t look just at their expertise. I look at things like how do they truly connect with the company’s values and mission. I look at things like drive, purpose and how they handle constructive feedback.</b><br/><br/>
            All this process is extremely critical and translates directly into the success of your company.
            </p>
          </div>
          <button
            className="cta-button cta-orange-animation"
            onClick={bookMarcosJReyes}
          >
            Pick a time slot
          </button>
        </div>

        <div className="footer">
          {/* <a
            className="footer-text"
            href={MARCOSJREYES_RESUME}
            target="_blank"
            rel="noreferrer"
          >Click here to view my resume</a> */}
          <p>© {new Date().getFullYear()} Marcos J. Reyes. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Consulting;
