import mjrlogo from '../media/itsmarcosjreyes-header-small.svg';
import mjrcodelogo from '../media/itsmarcosjreyes-header-small-code-logo.svg';
import itsmarcosjreyes01 from '../media/about/01-marcos-j-reyes-childhood-park-min.png';
import itsmarcosjreyes02 from '../media/about/02-marcos-j-reyes-childhood-2-min.png';
import itsmarcosjreyes03 from '../media/about/03-marcos-j-reyes-childhood-bday-min.png';
import itsmarcosjreyes04 from '../media/about/04-marcos-j-reyes-childhood-3-min.png';
import itsmarcosjreyes05 from '../media/about/05-marcos-j-reyes-college-01-min.png';
import itsmarcosjreyes06 from '../media/about/06-marcos-j-reyes-graduating-min.png';
import itsmarcosjreyes07 from '../media/about/07-marcos-j-reyes-3elevenmedia-min.png';
import itsmarcosjreyes08 from '../media/about/08-marcos-j-reyes-apple-engineer-wwdc-min.png';
import itsmarcosjreyes09 from '../media/about/09-marcos-j-reyes-family-min.png';
import itsmarcosjreyes10 from '../media/about/12-marcos-j-reyes-marcos-n-noah-min.png';
import itsmarcosjreyes11 from '../media/about/11-marcos-j-reyes-future-looking-ahead-min.png';
import itsmarcosjreyessig from '../media/about/marcos-j-reyes-signature.png';
import mjrcorevalue1 from '../media/about/itsmarcosjreyes-core-value-believe.png';
import mjrcorevalue2 from '../media/about/itsmarcosjreyes-core-value-go-the-extra-mile.png';
import mjrcorevalue3 from '../media/about/itsmarcosjreyes-core-value-be-vulnerable.png';
import mjrcorevalue4 from '../media/about/itsmarcosjreyes-core-value-be-an-energy-changer.png';
import mjrcorevalue5 from '../media/about/itsmarcosjreyes-core-value-love-change.png';
import React, { useEffect } from "react";
import './App.css';

const MARCOSJREYES_RESUME = './resume/MarcosJReyes-Apple-Sr-Lead-MobileEngineer-Mar2022.pdf';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <div className="main-container">
      <header className="header-branding-container">
        <img src={mjrlogo} className="header-branding-logo" alt="itsmarcosjreyes" />
        <img src={mjrcodelogo} className="header-branding-logo-code" alt="itsmarcosjreyes" />
        <p className="header-title-text">Who Am I?</p>
        <p className="header-branding-subtext">If I had to describe myself very quickly...</p>
        <h2 className="section-title linear-wipe">High Energy, Positive Attitude, Decisive, Relentless and Caring</h2>

        <div className="divider-orange"></div>
        </header>
        
        <div className="cool-project-listing">
          <div className="">
            <p className="cool-project-description">
            I grew up in a very humble environment. The value of Family, Fitness and Helping those around me, were certainly the 3 biggest pillars of my upbringing. 
            <img src={itsmarcosjreyes01} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            <br/><br/>
            I came to the United States at 13 years old.
            <br/><br/>
            Got scholarships and a full ride to University.
            <br/><br/>
            <img src={itsmarcosjreyes02} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            Went for Mathematics and Computer Engineering.
            <br/><br/>
            While being outstanding in school work, my mind was always somewhere else.
            <br/><br/>
            It was in business and impacting the world.
            <br/><br/>
            I almost got a record deal and that fell through.
            <br/><br/>
            <img src={itsmarcosjreyes03} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            I started my own record label and that failed as well, but I do believe I helped a friend in finding that true connection with music. That alone, made the label all worth it.
            <br/><br/>
            I had to live in the shadows and move from job to job every year because of an immigration status I couldn’t really comprehend.
            <br/><br/>
            Few years forward and I found myself literally running away from officers who showed up one morning to deport me and my family to a land I barely knew.
            <br/><br/>
            A few more attempts at entrepreneurship and a lot more surpassed failures have brought me here, to where I am today.
            <br/><br/>
            I have always been a programmer, a software engineer at heart.
            <br/><br/>
            <img src={itsmarcosjreyes04} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            Since I was 8, I used to play around with my dad’s computer and work on little programs that would do simple stuff.
            <br/><br/>
            I have been the lead engineer behind every one of the companies I’ve had and very company that I’ve been a part of.
            <br/><br/>
            Besides programming, Health and Wellness have been strong pillars in my life, though I not always payed attention to it or was truly aware of it.
            <br/><br/>
            When I was young, I was in a soccer team, baseball team… I even practiced Martial Arts… And I was always one of the fastest kids in school.
            <br/><br/>
            I had this deep desire to always be fit and strong. I think that might have been due to the fact that I was extremely short. As a matter of fact, people used to call me “The Midget”.
            <br/><br/>
            <img src={itsmarcosjreyes05} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            I think that deep down inside, I wanted to be strong and smart because I didn’t want my height to be the one attribute that determined the outcome of my life.
            <br/><br/>
            Since an early age I learned that WE dictate what matters.
            <br/><br/>
            That’s something we should never forget. We choose what and how our life will be determined. It is entirely 100% up to us!
            <br/><br/>
            And looking back at it, I’m very happy that I was very short.  Because of it, self-growth mentally and physically were always my priorities.
            <br/><br/>
            In order to survive and adjust to the new life in the United States, I found myself working in construction, warehouses, cleaning companies, fast food restaurants, banks, engineering companies, recording studios and even selling life insurance.
            <br/><br/>
            <img src={itsmarcosjreyes06} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            Every 8 months or so, I had to renew all my legal documentation to work and while the government processed those documents, I would enter this blackout period where I would always be let go of the job I had at the time, because I couldn’t legally work anymore until my new permit arrived.
            <br/><br/>
            After the situation with immigration was resolved and our case was heard all over the news for a month, I decided to keep moving forward. After all, being set back by messed up situations and having to start over again and again was something I had to face often and it no longer caused any stress to me.
            <br/><br/>
            As you seek and face the uncomfortable, you learn what you are truly made of.
            <br/><br/>
            I learned that you have to truly love each and every test life throws your way.
            <br/><br/>
            Finally, I found some sense of stability in regards to my professional life and my entrepreneurial goals.
            <br/><br/>
            <img src={itsmarcosjreyes07} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            Then… I randomly land at the hospital with a rare case of meningitis.
            <br/><br/>
            Up until this point in my life, starting over had never faced me, and I was never scared of anything life threw my way.
            <br/><br/>
            But this time, this time it was different.
            <br/><br/>
            For the very first time, the obstacle I was crossing could put an end to every single one of my dreams.
            <br/><br/>
            This time, I was scared.
            <br/><br/>
            Because of God, because of fate, because of the universe, because of life, because of whatever or whoever you believe in, I recovered.
            <br/><br/>
            <img src={itsmarcosjreyes08} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            If my wife had doubts about me getting better, she never showed any. She helped me in believing that this was nothing but another starting over point for me, and thankfully it was.
            <br/><br/>
            Those days made me realize how truly delicate our life is. The only requirement to leave this world for good is to be alive.
            <br/><br/>
            From that point forward, I have tried my very hardest to better my mind and body with an intense drive every single day.
            <br/><br/>
            <img src={itsmarcosjreyes09} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            I have learned that many have crossed obstacles way bigger than mine and still found a way to be in their best shape physically and mentally, while conquering their dreams.
            <br/><br/>
            I truly believe that it doesn’t matter where you come from, or what you have crossed.
            <br/><br/>
            There is always this ONE thing you can do everyday so that when you go to bed, you are 1% better than the person you were yesterday.
            <br/><br/>
            <img src={itsmarcosjreyes10} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            That’s how I have been able to cross all I have crossed.
            <br/><br/>
            Through Perseverance, Determination, Belief and insane Passion into everything I do.
            <br/><br/>
            You must know that there is always greatness beyond every obstacle.
            <br/><br/>
            My story is still being written. But the one constant I can guarantee you will see,  is the fact that I will not stop until the world understands that their Health and Wellness and their dreams need to be the very first thing they think about when they wake up, and the last thing in their mind before going to sleep.
            <img src={itsmarcosjreyes11} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
            <br/><br/>
            </p>
          </div>
          
          <div className="divider-orange"></div>

          <div className="cool-project-listing">
            <p className="cool-project-description">
              One day, as I look back into the biggest obstacles crossed and the biggest achievements of my life…<br/><br/>
              I know that I will be able to say:
            </p>
            <h2 className="section-title linear-wipe">
            “I gave it 110%. Every. Single. Time.<br/>To my family, to my dreams, to me.<br/>Positively impacting the world is my legacy.”
            </h2>
            <img src={itsmarcosjreyessig} className="section-image" alt="@itsmarcosjreyes - Marcos J. Reyes" />
          </div>
        </div>

        <div className="spacer"></div>
        <div className="divider-orange"></div>

        <div className="cool-project-listing">
          <p className="header-title-text">My Core Values</p>
          <p className="cool-project-description">
          While being the Founder of VEEDAH, I wrote these as the core values of the company after spending a very long time looking inward and analyzing what is it that truly drives the what, how and why I do the things I do.
          <br/><br/>
          I realized those weren't just the values for the company we created, those are my core values.
          </p>
        </div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
            <img src={mjrcorevalue1} className="" alt="Marcos J Reyes - Core Values - Believe" />
          </div>
          <div className="seventy-percent-width">
          <p className="cool-project-title ">Believe</p>
            <p className="cool-project-description">
            I believe in me. I believe in every little thing I do. I believe that I am a crucial piece in creating the biggest movement of positive change that the world will ever see. I believe in my skills. I firmly believe that everything I dream will materialize.
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
          <img src={mjrcorevalue2} className="" alt="Marcos J Reyes - Core Values - Go The Extra Mile" />
          </div>
          <div className="seventy-percent-width">
          <p className="cool-project-title ">Go the Extra Mile</p>
            <p className="cool-project-description">
            I always go above and beyond what is expected. I go the extra mile in regards to the work I do on a daily basis. I go the extra mile in the way I impact others’ lives in a positive manner. I go the extra mile to better each life I touch. I go the extra mile with remarkable passion and discipline in every little thing I do.
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
          <img src={mjrcorevalue3} className="" alt="Marcos J Reyes - Core Values - Be Vulnerable" />
          </div>
          <div className="seventy-percent-width">
          <p className="cool-project-title ">Be Vulnerable</p>
            <p className="cool-project-description">
            I accept my failures. I accept when I'm wrong. I understand that life happens FOR us and not TO us, so I don’t have a problem accepting when things don’t go my way and I know it’s all part of the process. Being vulnerable is my best quality; that’s how I truly connect with those around me.
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
          <img src={mjrcorevalue4} className="" alt="Marcos J Reyes - Core Values - Be an Energy Changer" />
          </div>
          <div className="seventy-percent-width">
          <p className="cool-project-title ">Be an Energy Changer</p>
            <p className="cool-project-description">
            I always change the energy in any room I walk in positively. Through humbleness, I are the most approachable person everywhere I go. I know that I'm not better than anyone and no one is better than me. I focus on always giving off great energy and positivity to everyone. I know that life will return that same energy I put out.
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
          <img src={mjrcorevalue5} className="" alt="Marcos J Reyes - Core Values - Love Change" />
          </div>
          <div className="seventy-percent-width">
          <p className="cool-project-title ">Love Change</p>
            <p className="cool-project-description">
            I love change. I look forward to change as I know that is the only way to make progress and become better. Even if I'm used to doing something in a specific manner, I accept new ideas and welcome new ways of doing things.
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="footer">
          {/* <a
            className="footer-text"
            href={MARCOSJREYES_RESUME}
            target="_blank"
            rel="noreferrer"
          >Click here to view my resume</a> */}
          <p>© {new Date().getFullYear()} Marcos J. Reyes. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
