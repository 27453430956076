import React from "react";
import { NavLink } from "react-router-dom";
import './App.css';

function Navigation() {
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="container">
          <div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home &nbsp;
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/consulting">
                  Consulting &nbsp;
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/kinetic-app-engine">
                  Kinetic App Engine
                </NavLink>
              </li> */}
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/we-all-make-history">
                  W.A.M.H
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/about">
                  About
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;