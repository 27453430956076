import kaelogo from '../media/kinetic-app-engine/itsmarcosjreyes-header-small-kineticappengine-top.svg';
import kaecodelogo from '../media/kinetic-app-engine/itsmarcosjreyes-header-small-kineticappengine-logo.svg';
import kae01 from '../media/kinetic-app-engine/kinetic-app-engine-01-min.png';
import kae02 from '../media/kinetic-app-engine/kinetic-app-engine-02-min.png';
import kae03 from '../media/kinetic-app-engine/kinetic-app-engine-03-min.png';
import kae04 from '../media/kinetic-app-engine/kinetic-app-engine-04-min.png';
import kae05 from '../media/kinetic-app-engine/kinetic-app-engine-05-min.png';
import kae06 from '../media/kinetic-app-engine/kinetic-app-engine-06-min.png';
import kae07 from '../media/kinetic-app-engine/kinetic-app-engine-07-min.png';
import kae08 from '../media/kinetic-app-engine/kinetic-app-engine-08-min.png';
import React, { useEffect } from "react";
import './App.css';

const MARCOSJREYES_RESUME = './resume/MarcosJReyes-Apple-Sr-Lead-MobileEngineer-Mar2022.pdf';
const KINETICAPPENGINE_INVEST = 'By investing $3,250 you can have your idea or business running with real apps in the real world and start profiting now';
const bookMarcosJReyes = async () => {
  console.log('KineticAppEngine goes for Sale starting July 11th');
};
const KINETICAPPENGINE_DATE = 'KineticAppEngine goes for sale starting July 11th';

function KAELanding() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <div className="main-container">
      <header className="header-branding-container">
        <img src={kaelogo} className="header-branding-logo" alt="itsmarcosjreyes" />
        <img src={kaecodelogo} className="header-branding-logo-code" alt="itsmarcosjreyes" />
        <p className="header-title-text">Kinetic App Engine</p>
        <p className="header-branding-subtext">Launch. Disrupt. Scale. At unmatched speed.</p>

        <div className="divider-orange"></div>
      </header>
        
        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">The problem we solve 💡</h2>
          <div className="">
            <p className="cool-project-description">Effectively and efficiently launch and maintain a fully native app for both iOS and Android. While developing and shipping new features fast. Never having to slow down because of new changes by Apple or Android.
            <img src={kae07} className="section-image" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">Why ReactNative or Flutter aren't the solution</h2>
          <div className="">
            <p className="cool-project-description">These are some of the reasons why these frameworks aren't as great as they seem at first glance:<br/><br/>
            <ul>
              <li>Performance is not always optimal for many UI components so workarounds are a must, specially if you are handling things like Image transformation, Video playback, Bluetooh, or any other highly-interactive feature</li>
              <li>Late-to-market implementing all new features released by Apple and Google on a yearly basis (This is due to the fact that you need to wait for the SDKs to “translate” all newly released components) Sometimes this process of “catching up” takes up to 6 months</li>
              <li>Risk of being rejected the App Store or Play Store</li>
              <li>The Client API is usually not included in services that offers App Development; and even if it is, Client API is not taken into account when polishing performance and User Experience on apps</li>
              <li>Codebase is messy in nature and it’s hard to transition teams or even grow the team to efficiently scale and expand</li>
              <li>Airbnb actually reverted back to native after giving ReactNative a big push and attempt to run their platform</li>
              <li>Facebook, the main stakeholder in ReactNative uses such platform for minimal screens</li>
              <li>Google, the main stakeholder in Flutter, only uses such SDK for 1 app out of the plethora they own</li>
            </ul>
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">A Real Solution</h2>
          <div className="">
            <p className="cool-project-description">You could hire a full team to develop your ClientAPI, DevOps, native iOS and Android apps.<br/><br/>
            This is a valid solution, but in comparison to using KineticAppEngine, it will cost quite a lot more.<br/><br/>
            By using KineticAppEngine, you would save yourself incredible amount of development, time and money.<br/><br/>
            <b>You will be able to take your product/idea and test it in real life to have precise feedback on its market fit.</b>
            <img src={kae03} className="section-image" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">So, what is KineticAppEngine?</h2>
          <div className="">
            <p className="cool-project-description">The KineticAppEngine is an all encompassing solution to launch, maintain and improve a full platform available in iOS and Android. The KineticAppEngine was materialized by 6 individuals who were formerly part of VEEDAH, Inc, including myself.<br/><br/>
<b>We developed the engine in order to launch our Health and Wellness platform in record-time while sustaining thousands of users and shipping new features at an extremely fast pace.</b><br/><br/>
We tested the engine in the real world with our very own platform and we quickly realized how powerful this engine truly was.
That is why now, we are making it available to the world.
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>
        <div className="">
          <p className="investment-description">
            {KINETICAPPENGINE_INVEST}<br/>
          </p>
        </div>
        <button
          className="cta-button cta-teal-animation"
          onClick={bookMarcosJReyes}
        >
          {KINETICAPPENGINE_DATE}
        </button>

        <div className="divider-orange"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">KineticAppEngine: iOS, Android and ClientApi!</h2>
          <div className="">
            <p className="cool-project-description"><b>All ready for you! Deploy your whole infrastructure within minutes!</b><br/><br/>
            <ul>
              <li>100% Native code for iOS (Swift) and Android (Kotlin)</li>
              <li>First-to-market in regards to new features release by Apple and Google</li>
              <li>Server-driven UX/UI that allows for fast iterations, fast deployments and app updates without waiting for review times</li>
              <li>Latest vector-based animations that give the apps a captivating look and feel</li>
            </ul>
            <b>ClientAPI is included! What does this mean?</b><br/><br/>
            The apps are server-driven and they were built in parallel with an extremely efficient and scalable ClientAPI using the very best AWS technologies have to offer.<br/>
            By having this powerful combination, we can achieve complete re-designs and screen overhauls without having to go through App Store approvals.<br/><br/>
            We can add/remove features all by a single deployment in ClientAPI side, making full features available to all users within minutes.
            Best part? All serverless. You will be shocked by how cheap your infrastructure will cost monthly! We can bet you've never seen anything like it.
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>
        <div className="">
          <p className="investment-description">
            {KINETICAPPENGINE_INVEST}<br/>
          </p>
        </div>
        <button
          className="cta-button cta-teal-animation"
          onClick={bookMarcosJReyes}
        >
          {KINETICAPPENGINE_DATE}
        </button>

        <div className="divider-orange"></div>

        <h2 className="section-title linear-wipe">Features</h2>
        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
            <img src={kae01} className="fit-image-rounded-square" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
          </div>
          <div className="seventy-percent-width">
            <p className="cool-project-description">
              <ul className="large-items">
                <li>Fast registrations and login flows already developed in ClientAPI using AWS Cognito</li>
                <li>Ability to add other registrations forms rapidly</li>
                <li>Fast and friendly forgot password flows</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="seventy-percent-width">
            <p className="cool-project-description">
            <ul className="large-items">
                <li>Advertising or Sponsored content can be added dynamically to any screen in any position</li>
                <li>Custom pre-scheduled content on holidays, special events, or even birthdays</li>
                <li>Custom CTAs changed dynamically</li>
              </ul>
            </p>
          </div>
          <div className="thirty-percent-width push-right">
            <img src={kae02} className="fit-image-rounded-square" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
            <img src={kae04} className="fit-image-rounded-square" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
          </div>
          <div className="seventy-percent-width">
            <p className="cool-project-description">
              <ul className="large-items">
                <li>Your certified professionals can have their own screens with their published content reflected</li>
                <li>Define the categories that make the most sense for your users, engage them quick</li>
                <li>All inclusive search</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="seventy-percent-width">
            <p className="cool-project-description">
            <ul className="large-items">
                <li>Add articles based on the information your users are asking</li>
                <li>Users can save the articles of their interests for later reading and sharing</li>
                <li>Questions on articles trigger Push Notifications to the certified author for immediate engagement with the user</li>
              </ul>
            </p>
          </div>
          <div className="thirty-percent-width push-right">
            <img src={kae05} className="fit-image-rounded-square" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
          </div>
        </div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
          <div className="thirty-percent-width push-left">
            <img src={kae06} className="fit-image-rounded-square" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
          </div>
          <div className="seventy-percent-width">
            <p className="cool-project-description">
              <ul className="large-items">
                <li>Streamlined video playback with options to render landscape and portrait videos</li>
                <li>Video details screen to ask questions to certified professional about such content</li>
                <li>Bookmark video content for later viewing</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="divider-orange"></div>
        <div className="">
          <p className="investment-description">
            {KINETICAPPENGINE_INVEST}<br/>
          </p>
        </div>
        <button
          className="cta-button cta-teal-animation"
          onClick={bookMarcosJReyes}
        >
          {KINETICAPPENGINE_DATE}
        </button>
        <div className="divider-orange"></div>

        <div className="spacer"></div>

        <div className="cool-project-listing">
        <h2 className="section-title linear-wipe">And there's more to fuel your healthy growth</h2>
          <div className="">
            <p className="cool-project-description">
            <ul className="large-items">
                <li>Subscription purchase handling for both App Store and Play Store</li>
                <li>ClientAPI is all serverless, which means infrastructure costs are extremely low and you only pay while users are active on your platform</li>
                <li>Analytics tracking already included</li>
                <li>Custom Push Notifications specifically tailored to increase engagement between certified professionals and users</li>
                <li>Light Mode and Dark Mode fully supported to stay up to date with users expectations</li>
                <li>Landing Screen variations</li>
              </ul>
              <img src={kae08} className="section-image" alt="Kinetic App Engine. Launch. Disrupt. Scale. At unmatched speed." />
            </p>
          </div>
          <button
            className="cta-button cta-teal-animation"
            onClick={bookMarcosJReyes}
          >
            {KINETICAPPENGINE_DATE}
          </button>
        </div>

        <div className="divider-orange"></div>
        <div className="">
          <p className="investment-description">
            If you have any questions, please contact me at <br/>
            <a href= "mailto: kineticappengine@kineticmatrix.io" className="linear-wipe">kineticappengine@kineticmatrix.io</a>
          </p>
        </div>

        <div className="footer">
          {/* <a
            className="footer-text"
            href={MARCOSJREYES_RESUME}
            target="_blank"
            rel="noreferrer"
          >Click here to view my resume</a> */}
          <p>© {new Date().getFullYear()} Marcos J. Reyes. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default KAELanding;
